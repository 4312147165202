* {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--dark);
  scroll-behavior: smooth;
}

main {
  min-height: 70vh;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

main {
  padding-top: 6%;
}

button {
  line-height: normal !important;
}

:root {
  --primary: #305BF2;
  --secondary: #E7ECFD;
  --dark: #1A1E2B;
  --white: #E5E5E5;
  --blue: #2949BA;
  --dark-blue: #0C1D59;
  --brand-color-3: #FF6B00;
  --brand-color-light-1: #BBC9FB;
  --brand-color-lighten-1: #8EA5F7;
  --brand-color-lighten-2: #72DEFF;
  --brand-color-lighten-3: #FFF0E5;
  --brand-color-extra-light-1: #E7ECFD;
  --brand-color-extra-light-2: #E5F9FF;
}